import React from 'react';
import styled, { css } from 'styled-components';
import { MainContentColumnWrapper } from '../../components/layout/Layout';
import orbits3 from '../../assets/images/orbits3.svg';
import paragraphAccent from '../../assets/images/paragraphAccent.svg';
import {
  GradientTitle,
  TextMedium,
  Upheader,
} from '../../components/atoms/Typography/Typography';
import { submitHubSpotContactForm } from '../../services/contactForm';
import { forScreenAtMost1000pxWide } from '../../styles/mediaQueries';
import ContactForm from './ContactForm';

const ContactFormSection = (): JSX.Element => (
  <Wrapper>
    <MainContentColumnWrapper>
      <InnerWrapper>
        <FormDescription>
          <Upheader $color="delicateAccented">Contact us</Upheader>
          <Header>
            Learn more about our antibody analytics solutions and services.
          </Header>
          <Paragraph>Have a question? Drop us a line</Paragraph>
        </FormDescription>
        <ContactForm onSubmit={submitHubSpotContactForm} />
      </InnerWrapper>
    </MainContentColumnWrapper>
  </Wrapper>
);

const Wrapper = styled.div`
  ${forScreenAtMost1000pxWide(css`
    overflow: hidden;
  `)}
`;

const InnerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 40px;
  padding: 240px 100px 0 100px;

  ${forScreenAtMost1000pxWide(css`
    flex-direction: column;
    gap: 0px;
    padding: 134px 0 0 0;
  `)}
`;

const FormDescription = styled.div`
  position: relative;

  :before {
    content: '';
    position: absolute;
    z-index: -1;
    top: -370px;
    left: -530px;
    width: 916px;
    height: 916px;
    background: url('${orbits3}');
    background-size: cover;
  }
`;

const Header = styled(GradientTitle).attrs({ $gradient: 'primary' })`
  max-width: 358px;
`;

const Paragraph = styled(TextMedium).attrs({ $color: 'delicateAccented' })`
  margin: 34px 0 0 33px;
  max-width: 300px;
  position: relative;

  :before {
    content: '';
    position: absolute;
    top: -4px;
    left: -76px;
    width: 36px;
    height: 64px;
    background: url('${paragraphAccent}') no-repeat;
  }

  ${forScreenAtMost1000pxWide(css`
    margin-top: 22px;
    margin-bottom: 40px;
    margin-left: 0;

    :before {
      display: none;
    }
  `)}
`;

export default ContactFormSection;
