import React from 'react';
import styled, { css } from 'styled-components';
import {
  TextLarge,
  Upheader,
} from '../../components/atoms/Typography/Typography';
import { MainContentColumnWrapper } from '../../components/layout/Layout';
import { forScreenAtMost1000pxWide } from '../../styles/mediaQueries';

const AddressSection = (): JSX.Element => (
  <Wrapper>
    <MainContentColumnWrapper>
      <InnerWrapper>
        <InnerBlock>
          <TextLarge $color="tertiary" $fontWeight="700" as="span">
            NaturalAntibody S.A.
          </TextLarge>
          <TextLarge $color="delicateAccented">
            KRS: 0000887528, NIP: 8522668876, REGON: 387657763
          </TextLarge>
        </InnerBlock>
        <InnerBlock>
          <Upheader $color="delicateAccented">Headquarters</Upheader>
          <TextLarge $color="delicateAccented">
            Al. Piastów 22, 71-064 Szczecin
          </TextLarge>
        </InnerBlock>
        <InnerBlock>
          <Upheader $color="delicateAccented">R&D department</Upheader>
          <TextLarge $color="delicateAccented">
            Krakowski Park Technologiczny KPT
            <br />
            Podole 60, 30-394 Kraków
          </TextLarge>
        </InnerBlock>
      </InnerWrapper>
    </MainContentColumnWrapper>
  </Wrapper>
);

const Wrapper = styled.div``;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  margin-top: 130px;
  margin-bottom: 69px;
  padding-left: 100px;

  ${forScreenAtMost1000pxWide(css`
    padding-left: 0px;
  `)}
`;

const InnerBlock = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 5px;
`;

export default AddressSection;
