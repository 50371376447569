import { ErrorMessage } from 'formik';
import React from 'react';
import styled, { css } from 'styled-components';

interface TextFieldProps {
  longText?: boolean;
  name: string;
  label: string;
  value: string;
  error: string | boolean | undefined;
  onChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  onBlur: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
}

const TextField = ({
  longText,
  name,
  label,
  value,
  error,
  onChange,
  onBlur,
}: TextFieldProps): JSX.Element => {
  return (
    <Wrapper>
      <ErrorMessage component={ErrorContainer} name={name} />
      <Label htmlFor={name}>{label}</Label>
      {longText ? (
        <Textarea
          hasError={!!error}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          placeholder={label}
          value={value}
        />
      ) : (
        <TextInput
          hasError={!!error}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          placeholder={label}
          value={value}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: left;
`;

const Label = styled.label.attrs({ className: 'sr-only' })``;

const TextInput = styled.input.attrs({ type: 'text' })<{ hasError?: boolean }>`
  width: 100%;
  height: 54px;
  background-color: ${({ theme }) => theme.colors.backgrounds.secondaryPair};
  padding: 14px 23px;

  font-size: ${({ theme }) => theme.fontSizes.forms.input};
  line-height: ${({ theme }) => theme.lineHeights.forms.input};

  color: ${({ theme }) => theme.colors.texts.tertiary};

  ::placeholder {
    color: ${({ theme }) => theme.colors.texts.secondary};
  }

  border: none;

  ${({ hasError, theme }) =>
    hasError &&
    css`
      border: 1px solid ${theme.colors.borders.error};
    `}

  &:focus {
    outline: none;
    border: 1px solid ${({ theme }) => theme.colors.borders.focus};
  }
`;

const Textarea = styled(TextInput.withComponent('textarea'))`
  height: 126px;
`;

const ErrorContainer = styled.div`
  position: absolute;
  top: -18px;
  left: 0;
  color: ${({ theme }) => theme.colors.texts.error};
  font-size: ${({ theme }) => theme.fontSizes.forms.error};
`;

export default TextField;
