import { Formik, FormikHelpers, FormikProps } from 'formik';
import React, { useCallback, useState } from 'react';
import styled, { css } from 'styled-components';
import * as Yup from 'yup';
import { ExternalLink } from '../../components/atoms/Link';
import SubmitButtonAccented from '../../components/atoms/SubmitButtonAccented';
import CheckBoxField from '../../components/molecules/CheckBoxField';
import TextField from '../../components/molecules/TextField';
import routes from '../../routes';
import {
  ContactFormIssueType,
  ContactFormSubmissionResult,
} from '../../services/contactForm';
import { forScreenAtMost680pxWide } from '../../styles/mediaQueries';

export interface ContactFormValues {
  name: string;
  email: string;
  message: string;
  privacyPolicy: boolean;
}

const initialValues: ContactFormValues = {
  name: '',
  email: '',
  message: '',
  privacyPolicy: false,
};

const contactFormSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Name should have at least 2 characters')
    .required('Please tell us your name'),
  email: Yup.string()
    .email('Are you sure this is a correct e-mail?')
    .required('Please tell us your e-mail address'),
  message: Yup.string()
    .min(30, 'Could you elaborate more?')
    .required('What are you interested in?'),
  privacyPolicy: Yup.boolean().oneOf(
    [true],
    'You must agree with the privacy policy',
  ),
});

interface ContactFormProps {
  onSubmit: (values: ContactFormValues) => Promise<ContactFormSubmissionResult>;
}

enum RequestStatus {
  NOT_SENT = 'NOT_SENT',
  SENDING = 'SENDING',
  SENT = 'SENT',
  ERROR = 'ERROR',
}

const ContactForm = ({ onSubmit }: ContactFormProps): JSX.Element => {
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(
    RequestStatus.NOT_SENT,
  );
  const [submissionIssue, setSubmissionIssue] =
    useState<ContactFormIssueType>();

  const handleSubmit = useCallback<
    (
      values: ContactFormValues,
      actions: FormikHelpers<ContactFormValues>,
    ) => Promise<void>
  >(
    async (values, actions) => {
      setRequestStatus(RequestStatus.SENDING);
      const result = await onSubmit(values);
      if (result.success) {
        setRequestStatus(RequestStatus.SENT);
        actions.resetForm();
      } else {
        setRequestStatus(RequestStatus.ERROR);
        setSubmissionIssue(result.issue);
      }
      actions.setSubmitting(false);
    },
    [onSubmit, setRequestStatus],
  );

  return (
    <Wrapper>
      <Formik
        component={RawContactForm}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={contactFormSchema}
      />
      {requestStatus === RequestStatus.SENT && (
        <SuccessMessage>
          Thank you for your message.
          <br />
          We will get back to you in 2 busines days.
        </SuccessMessage>
      )}
      {requestStatus === RequestStatus.ERROR && (
        <FailureMessage>
          {submissionIssue === ContactFormIssueType.Email ? (
            <>
              Please check if provided e-mail is correct.
              <br />
              If problem persists, please use another address if possible.
            </>
          ) : (
            <>
              {"Sorry, we couldn't send your message."}
              <br />
              Please try again later.
            </>
          )}
        </FailureMessage>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 455px;
  height: 100%;
  background-color: white;
`;

const ResultMessage = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 30px;
  padding: 20px 40px;
  margin-bottom: -102px;
`;

const SuccessMessage = styled(ResultMessage)`
  color: ${({ theme }) => theme.colors.texts.okMessage};
  background: ${({ theme }) => theme.colors.backgrounds.okMessage};
`;

const FailureMessage = styled(ResultMessage)`
  color: ${({ theme }) => theme.colors.texts.errorMessage};
  background: ${({ theme }) => theme.colors.backgrounds.errorMessage};
`;

const RawContactForm = ({
  handleSubmit,
  handleChange,
  handleBlur,
  values,
  touched,
  errors,
  isSubmitting,
}: FormikProps<ContactFormValues>): JSX.Element => {
  return (
    <Form onSubmit={handleSubmit}>
      <TextField
        error={touched.name && errors.name}
        label="Name"
        name="name"
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.name}
      />
      <TextField
        error={touched.email && errors.email}
        label="E-mail"
        name="email"
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.email}
      />
      <TextField
        error={touched.message && errors.message}
        label="Message"
        longText
        name="message"
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.message}
      />
      <ContactLegalNotice>
        The administrator of personal data is NATURALANTIBODY S.A. with
        registered office in Szczecin, Al.&nbsp;Piastów&nbsp;22&nbsp;St.,
        71-064&nbsp;Szczecin,&nbsp;Poland. Personal data of Users of the contact
        form are processed in order to identify the sender and handle the
        inquiry sent via the provided form. More information on the processing
        of personal data, including your rights and Data Controller details can
        be found in our&nbsp;
        <ExternalLink $focusBg="focusSecondary" href={routes.privacyPolicy}>
          Privacy policy
        </ExternalLink>
        .
      </ContactLegalNotice>
      <Row>
        <CheckBoxField
          error={touched.privacyPolicy && errors.privacyPolicy}
          name="privacyPolicy"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.privacyPolicy}
        >
          I agree with the{' '}
          <ExternalLink $focusBg="focusSecondary" href={routes.privacyPolicy}>
            Privacy policy
          </ExternalLink>
        </CheckBoxField>
        <SubmitButtonAccented disabled={isSubmitting}>
          {isSubmitting ? 'Submitting...' : 'Submit'}
        </SubmitButtonAccented>
      </Row>
    </Form>
  );
};

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 25px;

  ${forScreenAtMost680pxWide(css`
    gap: 20px;
  `)}
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${forScreenAtMost680pxWide(css`
    margin-top: 20px;
    align-items: flex-start;

    > * {
      width: 100%;
    }

    ${SubmitButtonAccented} {
      width: unset;
      align-self: center;
    }

    flex-direction: column;
    gap: 30px;
  `)}
`;

const ContactLegalNotice = styled.div`
  color: ${({ theme }) => theme.colors.texts.delicateStronger};
  font-size: ${({ theme }) => theme.fontSizes.forms.legalText};
  text-align: justify;

  a {
    text-decoration: underline;
  }
`;

export default ContactForm;
