import { ErrorMessage } from 'formik';
import React from 'react';
import styled, { css } from 'styled-components';
import checkBoxTick from '../../assets/images/checkBoxTick.svg';
import { ExternalLink } from '../atoms/Link';

interface CheckBoxFieldProps {
  name: string;
  children: React.ReactNode;
  value: boolean;
  error: string | boolean | undefined;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
}

const CheckBoxField = ({
  name,
  children,
  value,
  error,
  onChange,
  onBlur,
}: CheckBoxFieldProps): JSX.Element => (
  <Wrapper>
    <ErrorMessage component={ErrorContainer} name={name} />

    <Label>
      <CheckBox
        checked={value}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
      />
      <CheckBoxTick hasError={!!error} />
      {children}
    </Label>
  </Wrapper>
);

const Wrapper = styled.div`
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.texts.tertiary};
`;

const Label = styled.label`
  display: flex;
  color: ${({ theme }) => theme.colors.texts.secondary};
  font-size: ${({ theme }) => theme.fontSizes.forms.input};
  line-height: ${({ theme }) => theme.lineHeights.forms.inputSmaller};

  ${ExternalLink} {
    color: ${({ theme }) => theme.colors.texts.tertiaryStronger};
    text-decoration: none;
    border-bottom: 1px solid
      ${({ theme }) => theme.colors.texts.delicateAccented};
    margin: 0 5px;
  }
`;

const CheckBoxTick = styled.div<{ hasError?: boolean }>`
  width: 21px;
  height: 21px;
  background: ${({ theme }) => theme.colors.backgrounds.secondaryPair};
  margin-right: 14px;
  cursor: pointer;

  input:checked + & {
    background-image: url(${checkBoxTick});
    background-position: center;
    background-repeat: no-repeat;
  }

  input:focus + & {
    border: 1px solid ${({ theme }) => theme.colors.borders.focus};
  }

  input:focus-visible + & {
    outline: 5px solid ${({ theme }) => theme.colors.backgrounds.focusSecondary};
  }

  ${({ hasError, theme }) =>
    hasError &&
    css`
      border: 1px solid ${theme.colors.borders.error};
    `}
`;

const CheckBox = styled.input.attrs({ type: 'checkbox' })`
  width: 0;
  height: 0;
  opacity: 0;
`;

const ErrorContainer = styled.div`
  position: absolute;
  top: -24px;
  left: 0;
  color: ${({ theme }) => theme.colors.texts.error};
  font-size: ${({ theme }) => theme.fontSizes.forms.error};
`;

export default CheckBoxField;
