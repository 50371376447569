import styled from 'styled-components';
import buttonAccent from '../../assets/images/buttonAccent.svg';
import { LinkColorStyles } from './Link';

const SubmitButtonAccented = styled.button.attrs({
  type: 'submit',
  $focusBg: 'focusSecondary',
})`
  ${LinkColorStyles}

  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.02em;
  font-weight: 700;
  height: 47px;
  line-height: 47px;
  padding: 0 25px 0 62px;
  color: ${({ theme }) => theme.colors.texts.primary};
  background-color: ${({ theme }) => theme.colors.backgrounds.buttonSecondary};
  background-image: url('${buttonAccent}');
  background-position: 25px center;
  background-repeat: no-repeat;
  backdrop-filter: blur(15px);
  border: none;
  border-radius: 51px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  :hover:not(:focus-visible) {
    box-shadow: inset 0px 0px 0px 1px
        ${({ theme }) => theme.colors.accents.primary},
      ${({ theme }) => theme.boxShadows.navMenuGlow};
  }

  :focus-visible {
    outline: none;
  }
`;

export default SubmitButtonAccented;
