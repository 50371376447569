import React from 'react';
import { FooterBoxContent } from '../components/layout/Footer';
import Layout from '../components/layout/Layout';
import AddressSection from '../pages-components/contact-us/AddressSection';
import ContactFormSection from '../pages-components/contact-us/ContactFormSection';

const IndexPage = (): JSX.Element => {
  return (
    <Layout
      backgroundColor="secondary"
      description="Learn more about our antibody analytics solutions and services."
      footerBoxContent={FooterBoxContent.None}
      title="Contact us"
    >
      <ContactFormSection />
      <AddressSection />
    </Layout>
  );
};

export default IndexPage;
