export interface ContactFormInput {
  name: string;
  email: string;
  message: string;
  privacyPolicy: true;
}

const config = {
  portalId: '9077933',
  formGuid: '735879a6-5ead-461b-8458-f1708a76be00',
} as const;

// https://legacydocs.hubspot.com/docs/methods/forms/submit_form
interface HubSpotFormSubmitResponse {
  redirectUri?: string;
  inlineMessage?: string;
  errors?: { message: string; errorType: string }[];
}

export enum ContactFormIssueType {
  PrivacyPolicy = 'PrivacyPolicy',
  Email = 'Email',
  Other = 'Other',
  Unknown = 'Unknown',
}

export type ContactFormSubmissionResult =
  | {
      success: true;
    }
  | {
      success: false;
      issue: ContactFormIssueType;
    };

export async function submitHubSpotContactForm(
  payload: ContactFormInput,
): Promise<ContactFormSubmissionResult> {
  if (!payload.privacyPolicy) {
    console.log('Privacy policy has to be agreed to'); // eslint-disable-line no-console

    return { success: false, issue: ContactFormIssueType.PrivacyPolicy };
  }

  const { firstName, lastName } = splitName(payload.name);

  const body = {
    fields: [
      {
        name: 'firstname',
        value: firstName,
      },
      {
        name: 'lastname',
        value: lastName,
      },
      {
        name: 'email',
        value: payload.email,
      },
      {
        name: 'message',
        value: payload.message,
      },
    ],
    legalConsetOptions: {
      consent: {
        // include this object when GDPR is enabled
        consentToProcess: true,
        text: 'I agree with the Privacy policy',
      },
    },
  };

  return fetch(
    `https://api.hsforms.com/submissions/v3/integration/submit/${config.portalId}/${config.formGuid}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    },
  )
    .then((response) => response.json() as Promise<HubSpotFormSubmitResponse>)
    .then((responseJson) => {
      if (responseJson.errors) {
        console.error(responseJson.errors); // eslint-disable-line no-console

        return {
          success: false,
          issue: responseJson.errors
            .map((error) => error.errorType)
            .find(
              (type) => type === 'INVALID_EMAIL' || type === 'BLOCKED_EMAIL',
            )
            ? ContactFormIssueType.Email
            : ContactFormIssueType.Other,
        };
      }

      return { success: true } as ContactFormSubmissionResult;
    })
    .catch(() => {
      return { success: false, issue: ContactFormIssueType.Unknown };
    });
}

const splitName = (name: string) => {
  const trimmedName = name.trim();
  const lastDelimiterIndex = trimmedName.lastIndexOf(' ');
  if (lastDelimiterIndex === -1) {
    return { firstName: trimmedName, lastName: '' };
  }

  return {
    firstName: trimmedName.substr(0, lastDelimiterIndex),
    lastName: trimmedName.substr(lastDelimiterIndex + 1),
  };
};
